export default {
  breadCrumb: "หน้าแรก",
  btnClick: "คลิก",
  btnClose: "ปิด",
  btnSearch: "ค้นหา",
  btnSearchClear: "ล้างค่าการค้นหา",
  btnReadMore: "อ่านเพิ่มเติม",
  btnWatchMore: "ดูเพิ่มเติม",
  btnProductCompare: "เปรียบเทียบผลิตภัณฑ์",
  btnDistributor: "ตัวแทนจำหน่าย",
  btnDownloadBrochure: "ดาวน์โหลดโบรชัวร์",
  btnCompare: "เปรียบเทียบ",
  btnSendMessage: "ส่งข้อความ",
  btnContactHR: "ติดต่อฝ่ายบุคคล",
  btnWatchMap: "ดูแผนที่เอจีซี",
  btnEnterSite: "เข้าสู่เว็บไซต์",
  contact: "ต้องการข้อมูลเพิ่มเติม ให้เราติดต่อกลับ",
  headers: {
    home: "หน้าแรก",
    product: "ผลิตภัณฑ์",
    aboutUs: "เกี่ยวกับเรา",
    footPrint: "ตัวอย่างผลงาน",
    map:"แผนที่ เอจีซี",
    saleChannel: "ช่องทางจำหน่าย",
    technicalSupport: "ข้อมูลทางเทคนิค",
    newsAndArticles: "ข่าวและบทความ",
    sustainability: "นวัตกรรมเพื่อความยั่งยืน",
    contactUs: "ติดต่อเรา",
    search: "ค้นหา",
    subProducts: {
      productAGC: "ผลิตภัณฑ์กระจกเอจีซี",
      clearFloatGlass: "กระจกโฟลตใส",
      underclearFloatGlass: {
        clearFloatGlass: "กระจกโฟลตใส",
      },
      floatGlass: "กระจกโฟลตสีตัดแสง",
      underFloatGlass: {
        floatGlass: "กระจกโฟลตสีตัดแสง",
        oceanGreenFloatGlass: "กระจกโฟลต โอเชียนกรีน",
        energyGreenFloatGlass: "กระจกโฟลต เอเนอร์จีกรีน",
        darkGreyFloatGlass: "กระจกโฟลต ดาร์กเกรย์",
        euroGreyFloatGlass: "กระจกโฟลต ยูโรเกรย์",
        euroBronzeFloatGlass: "กระจกโฟลต ยูโรบรอนซ์",
        skyBlueFloatGlass: "กระจกโฟลต สกายบลู",
      },
      coatingGlass: "กระจกโคดติ้ง",
      underCoatingGlass: {
        luGrey: "กระจกลักซ์เกรย์",
        solarTAGPlus: "กระจกสะท้อนความร้อน โซลาร์แทค พลัส",
        highEfficiencyLowE: "กระจกโลว-อี ประสิทธิภาพสูง",
      },
      mirrorGlass: "กระจกเงา",
      underMirrorGlass: {
        mirrorMirroTAG: "กระจกเงา มิโรแทค",
      },
      processedGlass: "กระจกแปรรูป",
      underProcessedGlass: {
        temperedGlassTemTAG: "กระจกเทมเปอร์ เทมแทค",
        insulatedGlassPairTAG: "กระจกฉนวน อินซูเลท แพร์แทค",
        laminatedGlassLamiTAG: "กระจกนิรภัยหลายชั้น ลามิเนต ลามิแทค",
      },
      specialProduct: "ผลิตภัณฑ์พิเศษ",
      underSpecialProduct: {
        sunEwatBIPV: "กระจกอาคารผสานเซลล์แสงอาทิตย์",
      },
      compare: "เปรียบเทียบผลิตภัณฑ์",
      findYourGlass: "ค้นหากระจกสำหรับคุณ",
    },
    subSustainability: {
      Sustainability: "นวัตกรรมเพื่อความยั่งยืน",
      AFTSustainabilityOverview: "แนวทางแบบองค์รวมเพื่อสนับสนุนความยั่งยืน",
      environmentalManagementSystem: "ระบบการจัดการสิ่งแวดล้อม",
      productionProcessAndTechnologies: "กระบวนการผลิตและเทคโนโลยี",
      environmentalAchievements: "ความสำเร็จด้านสิ่งแวดล้อม",
      environmentFriendlyProducts: "ผลิตภัณฑ์ที่เป็นมิตรกับสิ่งแวดล้อม",
      globalEnvironmentalFootprint: "ความรับผิดชอบต่อสิ่งแวดล้อม",
      sustainableBuildings: "อาคารยั่งยืน",
      UNSustainabilityDevelopmentGoals:
        "เป้าหมายการพัฒนาที่ยั่งยืนของสหประชาชาติ",
    },
  },
  footer: {
    addresses: {
      companyName:
        "บมจ. เอจีซี แฟลทกลาส (ประเทศไทย) สำนักงานใหญ่ และโรงงานสมุทรปราการ",
      address:
        "200 หมู่ 1 ถนนสุขสวัสดิ์ ตำบลปากคลองบางปลากด อำเภอพระสมุทรเจดีย์ จังหวัดสมุทรปราการ 10290",
      time: "เวลา : 8.00-17.00 น.",
      telephone: "โทร",
      fax: "แฟกซ์",
    },
    product: "ผลิตภัณฑ์",
    aboutUs: "เกี่ยวกับเรา",
    footPrint: "ตัวอย่างผลงาน",
    saleChannel: "ช่องทางจำหน่าย",
    technicalSupport: "ข้อมูลทางเทคนิค",
    newsAndArticles: "ข่าวและบทความ",
    sustainability: "นวัตกรรมเพื่อความยั่งยืน",
    contactUs: "ติดต่อเรา",
    topicSubscribe: "ไม่พลาดข่าวและบทความสาร และไอเดียใหม่ๆ",
    placeHolderSubscribe: "กรุณากรอกอีเมล",
    copyRight:
      "สงวนลิขสิทธิ์ พ.ศ. 2566 บริษัท เอจีซี แฟลทกลาส (ประเทศไทย) จำกัด มหาชน",
    privacyPolicy: "นโยบายความเป็นส่วนตัว",
    subAboutUs: {
      companyHistory: "ประวัติบริษัท",
      executiveVision: "วิสัยทัศน์",
      Chronology: "ลำดับเหตุการณ์สำคัญ",
    },
    subProducts: {
      clearFloatGlass: "กระจกโฟลตใส",
      floatGlass: "กระจกโฟลตสีตัดแสง",
      coatingGlass: "กระจกโคดติ้ง",
      mirrorGlass: "กระจกเงา",
      processedGlass: "กระจกแปรรูป",
      sunEwatBIPV: "กระจกอาคารผสานเซลล์แสงอาทิตย์",
      compare: "เปรียบเทียบผลิตภัณฑ์",
      findYourGlass: "ค้นหากระจกสำหรับคุณ",
    },
    subSustainability: {
      AFTSustainabilityOverview: "แนวทางแบบองค์รวมเพื่อสนับสนุนความยั่งยืน",
      environmentalManagementSystem: "ระบบการจัดการสิ่งแวดล้อม",
      productionProcessAndTechnologies: "กระบวนการผลิตและเทคโนโลยี",
      environmentalAchievements: "ความสำเร็จด้านสิ่งแวดล้อม",
      environmentFriendlyProducts: "ผลิตภัณฑ์ที่เป็นมิตรกับสิ่งแวดล้อม",
      globalEnvironmentalFootprint: "ความรับผิดชอบต่อสิ่งแวดล้อม",
      sustainableBuildings: "อาคารยั่งยืน",
      UNSustainabilityDevelopmentGoals:
        "เป้าหมายการพัฒนาที่ยั่งยืนของสหประชาชาติ",
    },
    subContactUs: {
      sendMessage: "ส่งข้อความถึงเรา",
      faq: "คำถามที่พบบ่อย",
    },
    subFootprint: {
      officeBuildings: "อาคารสำนักงาน",
      home: "ที่พักอาศัย",
      map: "แผนที่ เอจีซี",
    },
    subTechnicalSupport: {
      glassConfigurator: "การกำหนดค่ากระจก",
      download: "ดาวน์โหลด",
      lawAndRuleOfGlass: "กฏหมายและข้อบังคับการใช้กระจก",
    },
  },
  home: {
    title: "เราสร้างนวัตกรรมกระจกไทย",
    gradianTitle: "กระจกไทยอาซาฮี คือ กระจกเอจีซี",
    findYourGlass: {
      subTitle: "ค้นหากระจกที่ใช่สำหรับคุณ",
      lowBuilding: "อาคารแนวราบขนาดเล็ก",
      highBuilding: "อาคารสูง/ขนาดใหญ่/ขนาดใหญ่พิเศษ",
      glassType: "ประเภทกระจก",
      sheet: "แผ่นเดี่ยว",
      laminated: "ลามิเนต",
      insulated: "อินซูเลต",
      laminatedInsulated: "ลามิเนต+อินซูเลต",
      color: "สี",
      clear: "ใส",
      green: "เขียว",
      darkGrey: "ชาดำ",
      skyBlue: "ฟ้า",
      grey: "เทา/เข้ม",
      bronzeGold: "บรอนซ์(น้ำตาล)/ทอง",
      other: "อื่นๆ",
      btnAdvancedSearch: "ค้นหาขั้นสูง",
      properLight: "ระดับแสงส่องผ่านกระจก",
      properHeatProtection: "ระดับการป้องกันความร้อน",
      safety: "เพื่อความปลอดภัย",
      UVProtection: "ป้องกันรังสี UV",
      noiseReducer: "ลดเสียงรบกวน",
    },
    product: {
      agcFlatglass: "AGC Flatglass",
    },
    sustainability: {
      agcFlatglassThailand: "นวัตกรรมเพื่อความยั่งยืน",
      subTitle1:
        "บมจ. เอจีซี แฟลทกลาส (ประเทศไทย) คำนึงถึงความรับผิดชอบในทุกกระบวนการ",
      subTitle2:
        "เราจึงพัฒนาให้ทุกขั้นตอนการผลิต ปลอดภัยต่อสังคม ชุมชน และสิ่งแวดล้อม",
    },
  },
  product: {
    title: "ผลิตภัณฑ์",
    agcGlass: "กระจกเอจีซี",
    btnDistributor: "ตัวแทนจำหน่ายใกล้คุณ",
    compare: {
      titleCompare: "Compare",
      productCompare: "เปรียบเทียบกระจกรุ่นต่างๆ",
    },
    findYourGlass: {
      subTitle: "ค้นหากระจกที่ใช่สำหรับคุณ",
      lowBuilding: "อาคารแนวราบขนาดเล็ก",
      highBuilding: "อาคารสูง/ขนาดใหญ่/ขนาดใหญ่พิเศษ",
      glassType: "ประเภทกระจก",
      sheet: "แผ่นเดี่ยว",
      laminated: "ลามิเนต",
      insulated: "อินซูเลต",
      laminatedInsulated: "ลามิเนต+อินซูเลต",
      color: "สี",
      clear: "ใส",
      green: "เขียว",
      darkGrey: "ชาดำ",
      skyBlue: "ฟ้า",
      grey: "เทา/เข้ม",
      bronzeGold: "บรอนซ์(น้ำตาล)/ทอง",
      other: "อื่นๆ",
      btnAdvancedSearch: "ค้นหาขั้นสูง",
      properLight: "ระดับแสงส่องผ่านกระจก",
      properHeatProtection: "ระดับการป้องกันความร้อน",
      safety: "เพื่อความปลอดภัย",
      UVProtection: "ป้องกันรังสี UV",
      noiseReducer: "ลดเสียงรบกวน",
    },
    content: {
      features: "คุณสมบัติเด่น",
      usage: "การใช้งาน",
    },
  },
  findYourGlass: {
    subTitle: "ค้นหากระจกที่ใช่สำหรับคุณ",
    lowBuilding: "อาคารแนวราบขนาดเล็ก",
    highBuilding: "อาคารสูง/ขนาดใหญ่/ขนาดใหญ่พิเศษ",
    glassType: "ประเภทกระจก",
    sheet: "แผ่นเดี่ยว",
    laminated: "ลามิเนต",
    insulated: "อินซูเลต",
    laminatedInsulated: "ลามิเนต+อินซูเลต",
    color: "สี",
    clear: "ใส",
    green: "เขียว",
    darkGrey: "ชาดำ",
    skyBlue: "ฟ้า",
    grey: "เทา/เข้ม",
    bronzeGold: "บรอนซ์(น้ำตาล)/ทอง",
    other: "อื่นๆ",
    btnAdvancedSearch: "ค้นหาขั้นสูง",
    properLight: "ระดับแสงส่องผ่านกระจก",
    properHeatProtection: "ระดับการป้องกันความร้อน",
    safety: "เพื่อความปลอดภัย",
    UVProtection: "ป้องกันรังสี UV",
    noiseReducer: "ลดเสียงรบกวน",
    titleResult: "ผลการค้นหากระจกที่ใช่ หรือใกล้เคียงที่คุณต้องการ",
    operationType: "ประเภทการใช้งาน",
    protect: "ป้องกัน",
    list: "รายการ",
    technicalSupport: "ข้อมูลทางเทคนิค",
    properProtection: "ระดับการป้องกัน",
    heat: "ความร้อน",
    allBuilding: "อาคารแนวราบขนาดเล็ก/อาคารสูง/ขนาดใหญ่/ขนาดใหญ่พิเศษ",
    information:"เพื่อได้รับกระจกที่ตรงกับความต้องการ สามารถติดต่อทีมงานเอจีซีเพื่อรับคำปรึกษาเพิ่มเติม",
  },
  compare: {
    productCompare: "เปรียบเทียบกระจกรุ่นต่างๆ",
    model: "รุ่น",
    selectThickness: "เลือกความหนา",
    unitmm: "มม.",
    operationType: "ประเภทการใช้งาน",
    allBuilding: "อาคารแนวราบขนาดเล็ก/อาคารสูง/ขนาดใหญ่/ขนาดใหญ่พิเศษ",
    lowBuilding: "อาคารแนวราบขนาดเล็ก",
    highBuilding: "อาคารสูง/ขนาดใหญ่/ขนาดใหญ่พิเศษ",
    protect: "ป้องกัน",
    contact: "รับการช่วยเหลือโดยผู้เชี่ยวชาญ",
    information:"เพื่อได้รับกระจกที่ตรงกับความต้องการ สามารถติดต่อทีมงานเอจีซีเพื่อรับคำปรึกษาเพิ่มเติม",
  },
  sustainability: {
    title: "นวัตกรรมเพื่อความยั่งยืน",
  },
  news: {
    title: "ข่าวและบทความและกิจกรรม",
    content: {
      postDate: "โพสต์วันที่",
    },
  },
  footprint: {
    title: "ตัวอย่าง",
    title2: "ผลงาน",
    officeBuilding: "อาคารสำนักงาน",
    home: "ที่พักอาศัย",
    map: "แผนที่ เอจีซี",
    content: {
      buildName: "ชื่ออาคาร",
      type: "ประเภท",
      province: "จังหวัด",
      country: "ประเทศ",
      glassProduct: "ผลิตภัณฑ์กระจก",
      glassType: "ประเภทกระจก",
      usage: "การใช้งาน",
      architect: "สถาปนิก",
      installer: "ผู้ติดตั้ง",
    },
  },
  saleChannel: {
    title: "ช่องทางจำหน่าย",
    region: "ภูมิภาค",
    province: "จังหวัด",
    keywordSearch: "คำที่ต้องการค้นหา",
    placeHolderCompanyOrAdderss: "ระบุชื่อบริษัท หรือที่อยู่",
    title2: "ร้านที่คุณค้นหา หรือร้านที่ใกล้เคียง",
    all: "ทั้งหมด",
  },
  technicalSupport: {
    title: "ข้อมูลทางเทคนิค",
    glassConfigurator: "เครื่องมือปรับแต่งกระจก",
    detail:
      "เครื่องมือออนไลน์ที่จะช่วยร่างแบบกระจกที่เหมาะกับ โครงการของคุณ เพื่อการค้นหาผลิตภัณฑ์กระจกเอจีซี ได้เหมาะสม และมีประสิทธิภาพสูงสุด พร้อมสามารถ ดาวน์โหลดรายงาน รวมถึงข้อมูลทางเทคนิคด้านกระจกที่จำเป็น เพื่อนำไปประกอบในการออกแบบกระจกให้เหมาะสม กับโครงการของคุณ",
    download: "ดาวน์โหลด",
    subDownload: "รวบรวมข้อมูลสำคัญเกี่ยวกับกระจก",
    law: "กฏหมาย",
    subLaw: "และข้อบังคับการใช้กระจก",
  },
  contactUs: {
    title: "ติดต่อเรา",
    companyName: "บริษัท เอจีซี แฟลทกลาส (ประเทศไทย) จำกัด (มหาชน)",
    telephone: "โทร",
    fax: "แฟกซ์",
    addresses1: {
      companyName: "กระจกเอจีซี สำนักงานใหญ่ และโรงงานกระจกเอจีซี สมุทรปราการ",
      address:
        "200 หมู่ 1 ถนนสุขสวัสดิ์ ตำบลปากคลองบางปลากด อำเภอพระสมุทรเจดีย์ จังหวัดสมุทรปราการ 10290",
      time: "เวลาทำการ 08.00 - 17.00 น.",
    },
    addresses2: {
      companyName: "โรงงานกระจกเอจีซี อมตะ ซิตี้ ชลบุรี",
      address: "700/22 หมู่ 6 ตำบลหนองไม้แดง อำเภอเมือง จังหวัดชลบุรี 20000",
      time: "เวลาทำการ 08.00 - 17.00 น.",
    },
    addresses3: {
      companyName: "โรงงานกระจกเอจีซี อมตะ ซิตี้ ระยอง",
      address:
        "7/104 หมู่ 4 ทางหลวงหมายเลข 331 ตำบลมาบยางพร อำเภอปลวกแดง จังหวัดระยอง 21140",
      time: "เวลาทำการ 08.00 - 17.00 น.",
    },
    contactToSale: "ส่งข้อความถึงฝ่ายขาย",
    fullName: "ชื่อ-นามสกุล",
    company: "บริษัท",
    occupation: "อาชีพ",
    email: "อีเมล์",
    tel: "โทรศัพท์",
    interestProduct: "ผลิตภัณฑ์ที่สนใจ",
    topicContact: "หัวข้อที่ติดต่อ",
    selectMoreThanOne: "เลือกได้มากกว่า 1 ข้อ",
    message: "ข้อความ",
    acceptPrivacy: "ยอมรับเงื่อนไขการให้บริการ",
    headPrivacy: "หนังสือให้ความยินยอมเกี่ยวกับข้อมูลส่วนบุคคล",
    linePrivacy1:
      "ลูกค้าสามารถทำความเข้าในนโยบายความเป็นส่วนตัวของ AGC ได้ที่เว็บไซต์ www.agc-flatglass.co.th",
    linePrivacy2:
      "ลูกค้าสามารถสอบถามรายละเอียดหรือร้องเรียนได้ที่เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล",
    linePrivacy3:
      "AGC จะเก็บข้อมูลของลูกค้าเป็นเวลา 10 ปี หลังจากลูกค้าเลิกใช้ผลิตภัณฑ์และบริการของ AGC",
    linePrivacy4:
      "ลูกค้าสามารถถอนความยินยอมได้ที่ AGC Contact โทร. 02 815 5000 หรือช่องทางใหม่อื่นๆ ตามที่ประกาศไว้ในเว็บไซต์ของบริษัท",
    faq: "ถาม-ตอบ",
    placeholderCaptcha:"กรุณากรอกรหัสความปลอดภัย"
  },
  aboutUs: {
    title: "วิสัยทัศน์ กลุ่มบริษัทเอจีซี",
    subTitle: "ก้าวสู่อนาคต สร้างสรรค์สิ่งที่ดี เพื่อโลกที่สดใส",
    name: "เกี่ยวกับเรา",
    companyProfile: "ประวัติบริษัท",
    detail: `บริษัท เอจีซี แฟลทกลาส (ประเทศไทย) จํากัด (มหาชน) ก่อตั้งบริษัทฯ
    ในปี 2506 โดยการร่วมทุนระหว่าง ผู้ลงทุนไทย และอาซาฮี กลาส ประเทศญี่ปุ่น
    บริษัท เอจีซี <span class="nowrap">แฟลท</span><span class="nowrap">กลาส</span> (ประเทศไทย) จํากัด (มหาชน) เป็นผู้ผลิตกระจกแผ่นเรียบ
    ชั้นนำของไทย ผลิตกระจกในระบบโฟลต ซึ่งเป็นระบบการผลิตกระจกที่ทันสมัย
    ปัจจุบันผลิตกระจก<span class="nowrap">โฟลตใส</span> กระจกโฟลตสีตัดแสง กระจกเงา กระจกสะท้อนแสง
    กระจกโลว-อี กระจกแปรรูปชนิดต่างๆ เช่น กระจกเทมเปอร์ กระจกนิรภัยลามิเนต
    กระจกฉนวนความร้อน และกระจกเพื่อการอนุรักษ์พลังงาน เป็นต้น
    เกือบ 60 ปี ที่ บริษัท เอจีซี แฟลทกลาส (ประเทศไทย) จํากัด (มหาชน)
    มุ่งมั่น และได้ตอบสนองความต้องการของผู้ใช้ ผู้บริโภค ด้วยผลิตภัณฑ์กระจก
    และบริการที่มีคุณภาพในมาตรฐานระดับสากลภายใต้คำขวัญ
    <br><br>
    “ก้าวสู่อนาคต สรรค์สร้างสิ่งที่ดี เพื่อโลกที่สดใส”`,
    startTitle: "กระจกเอจีซี เริ่มต้นกิจการที่ประเทศญี่ปุ่น มากกว่า 100 ปี",
    startTitle2:
      "ดำเนินกิจการในประเทศไทยกว่า 59 ปี และ ขยายไปมากกว่า 30 ประเทศทั่วโลก",
    visionTitle: "วิสัยทัศน์",
    livisionTitle: "เราจะเป็นบริษัทที่น่าเชื่อถือเป็นอันดับ 1 และเป็นผู้นำในการสร้างความยั่งยืนในภูมิภาคด้วยเทคโนโลยีและความเชี่ยวชาญของเรา",
    historyTitle: "ลำดับเหตุการณ์สำคัญ",
    history: {
      head1: "พ.ศ. 2506 (ค.ศ. 1963)",
      detail1: `ก่อตั้ง บริษัท กระจกไทย จำกัด เมื่อวันที่ 24 มิถุนายน พ.ศ. 2506 โดยนายเกียรติ ศรีเฟื่องฟุ้ง`,
      head2: "พ.ศ. 2507 (ค.ศ. 1964)",
      detail2: `ก่อตั้งบริษัท กระจกไทย-อาซาฮี จำกัด โดยร่วมทุนกับบริษัท อาซาฮีกลาส`,
      head3: "พ.ศ. 2527 (ค.ศ. 1984)",
      detail3: `ก่อสร้างโรงงานผลิตกระจกโฟลตแห่งแรกในไทย`,
      head4: "พ.ศ. 2528 (ค.ศ. 1985)",
      detail4: `ก่อสร้างโรงงานผลิตกระจกเงาคุณภาพสูง ออกสู่ตลาดภายในประเทศ ภายใต้ชื่อ “MIRROTAG”`,
      head5: "พ.ศ. 2531 (ค.ศ. 1988)",
      detail5: `ก่อสร้างโรงงานผลิตกระจกสะท้อนแสง ภายใต้ชื่อ “SOLARTAG”`,
      head6: "พ.ศ. 2532 (ค.ศ. 1989)",
      detail6: `ก่อตั้งบริษัท บางกอกโฟลทกลาส จำกัด โดยการร่วมทุนระหว่าง อาซาฮีกลาส และ บริษัท กระจกไทยอาซาฮี จำกัด เข้าจดทะเบียนในตลาดหลักทรัพย์แห่งประเทศไทย ในวันที่ 25 ธันวาคม`,
      head7: "พ.ศ. 2534 (ค.ศ. 1991)",
      detail7: `บริษัท บางกอกโฟลทกลาส จำกัด เริ่มผลิตกระจกโฟลต ในวันที่ 1 ตุลาคม และก่อสร้างโรงงานผลิตกระจกเทมเปอร์ภายใต้ชื่อ “TEMPTAG”`,
      head8: "พ.ศ. 2538 (ค.ศ. 1995)",
      detail8: `ก่อสร้างโรงงานนผลิตกระจกฉนวนความร้อนภายใต้ชื่อ “PAIRTAG”`,
      head9: "พ.ศ. 2539 (ค.ศ. 1996)",
      detail9: `ได้รับการรับรองมาตรฐานการบริหารคุณภาพ ISO9002 จาก<span class="nowrap">สถานบัน</span> BVQI และเป็นผู้เดียวในอุตสาหกรรมผลิตกระจกในประเทศไทยที่ได้รับการรับรองมาตรฐาน ISO ในทุกกระบวนการผลิตของผลิตภัณฑ์`,
      head10: "พ.ศ. 2540 (ค.ศ. 1997)",
      detail10: `ก่อสร้างโรงงานกระจกโฟลตที่ จ.ระยอง`,
      head11: "พ.ศ. 2545 (ค.ศ. 2002)",
      detail11: `บริษัท กระจกไทยอาซาฮี จำกัด (มหาชน) และบริษัท บางกอก<span class="nowrap">โฟลทกลาส</span> จำกัด ได้ทำการควบรวมกิจการภายใต้ชื่อบริษัท กระจกไทยอาซาฮี จำกัด (มหาชน) ประกอบด้วย โรงงาน 3 แห่ง คือ โรงงานสมุทรปราการ, โรงงานชลบุรี (บางกอกโฟลทกลาสเดิม) และโรงงานระยอง โดยใช้ตราสัญลักษณ์ใหม่ “TAGC”`,
      head12: "พ.ศ. 2549 (ค.ศ. 2006)",
      detail12: `ก่อสร้างโรงงานผลิต กระจกนิรภัยลามิเนต ภายใต้ชื่อ “LAMITAG”`,
      head13: "พ.ศ. 2500 (ค.ศ. 2007)",
      detail13: `ในวาระครบรอบ 100 ปี ของบริษัท อาซาฮีกลาส จำกัด แห่งประเทศญี่ปุ่น มีการเปลี่ยนตราสัญลักษณ์ใหม่ โดยให้ใช้เหมือนกันทุกบริษัทที่อยู่ในเครือภายใต้สัญลักษณ์ “AGC”`,
      head14: "พ.ศ. 2554 (ค.ศ. 2011)",
      detail14: `ผลิตกระจกนิรภัยลามิเนต โลว-อี “LAMITAG-E” กระจกเพื่อการอนุรักษ์พลังงานสู่ตลาด`,
      head15: "พ.ศ. 2556 (ค.ศ. 2013)",
      detail15: `ครบรอบ 50 ปี บริษัท กระจกไทยอาซาฮี จำกัด (มหาชน)`,
      head16: "พ.ศ. 2557 (ค.ศ. 2014)",
      detail16: `แนะนำ เอเนอร์จี กรีน “ENERGY GREEN” กระจกเพื่อการอนุรักษ์พลังงานสู่ตลาด`,
      head17: "พ.ศ. 2558 (ค.ศ. 2015)",
      detail17: `เพิ่มกำลังการผลิตกระจกสะท้อนแสง และเริ่มผลิตกระจกโลว-อี ภายใต้ชื่อ “STOPRAY”`,
      head18: "พ.ศ. 2561 (ค.ศ. 2018)",
      detail18: `ครบรอบ 55 ปี แห่งความยิ่งใหญ่ในธุรกิจผู้ผลิตกระจกแผ่นเรียบ`,
      head19: "พ.ศ. 2562 (ค.ศ. 2019)",
      detail19: `บริษัท กระจกไทยอาซาฮี จำกัด (มหาชน) เปลี่ยนชื่อเป็น บริษัท เอจีซี แฟลทกลาส (ประเทศไทย) จำกัด (มหาชน)`,
    },
  },
  maps: {
    title: "แผนที่ เอจีซี",
    subTitle: "60 โปรเจกต์แห่งความภาคภูมิใจ",
    subTitle2: "มากกว่า 60 ปี",
    region: "ภาค",
    province: "จังหวัด",
    typeBuild: "ประเภท",
    typeGlass: "ประเภทกระจก",
  },
  toolbar: {
    title: "ค้นหาบนเว็บไซต์ เอจีซี แฟลทกลาส (ประเทศไทย)",
    hit: "คำค้นหายอดนิยม",
    placeholder: "คำที่ต้องการค้นหา"
  },
  search: {
    title: "ผลการค้นหา",
    result: "คำค้นหาของคุณ",
    placeholder: "ระบุคำที่ต้องการค้นหา",
    notFoundGlass: "ไม่พบข้อมูลกระจกที่คุณค้นหา",
    notFoundDistribution: "ไม่พบร้านในพื้นที่ที่คุณค้นหา อย่างไรก็ตาม คุณสามารถค้นหาร้านในบริเวณใกล้เคียงได้"
  }
};
